import React from "react";
import "./style.css";

function DisplayNotice(props) {
  return (
    <div className="notice">
      <p style={{ backgroundColor: props.noticeColor }}>
        {props.qurrentNotice}
      </p>
    </div>
  );
}

export default DisplayNotice;
