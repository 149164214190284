import React from "react";
import "./style.css";

class ButtonNext extends React.Component {
  render() {
    return (
      <button className="buttonnext" onClick={this.props.onClickNext}>
        next quiz »
      </button>
    );
  }
}

export default ButtonNext;
