const myData = [
    {   
    id: 0, 
    q: "Who was the first man in space?",
    a1: {
        text: "J.Gagarin", value: true
        },
    a2: {
        text: "M.Jordan", value: false
        },
    a3: {
        text: "N.Armstrong", value: false
        },
    a4: {
        text: "S.Hawking", value: false
        }
    },

    {
    id: 1, 
    q: "What is the theory of relativity about?",
    a1: {
        text: "Space & time", value: true
        },
    a2: {
        text: "Evolution", value: false
        },
    a3: {
        text: "Philosophy", value: false
        },
    a4: {
        text: "Math", value: false
        }
    },

    {
    id: 3, 
    q: "23 * 20 = ?",
    a1: {
        text: "510", value: false
        },
    a2: {
        text: "460", value: true
        },
    a3: {
        text: "777", value: false
        },
    a4: {
        text: "220", value: false
        }
    }
    
]



export default myData