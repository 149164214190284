import React from "react";
import "./App.css";

import Question from "./components/Question/Question";
import Answer from "./components/Answer/Answer";
import myData from "../src/data";
import ButtonPrevious from "../src/components/ButtonPrevious/ButtonPrevious";
import ButtonNext from "../src/components/ButtonsNext/ButtonNext";
import DisplayNotice from "../src/components/DisplayNotice/DisplayNotice";

class App extends React.Component {
  state = {
    myQuestData: myData,
    index: 0,
    notice: "",
    noticeColor: ""
  };

  // next button function
  _nextQuiz() {
    const currentIndex = this.state.index;
    const arraylength = myData.length - 1;

    if (currentIndex < arraylength) {
      // show next Question
      this.setState({ index: currentIndex + 1 });
    }
    // after the last question you get the first one
    else {
      this.setState({ index: 0 });
    }
    this.setState({ notice: "" });
  }

  // previous button function
  _previousQuiz() {
    const currentIndex = this.state.index;
    const arraylength = myData.length - 1;

    if (currentIndex >= arraylength) {
      // show previous Question
      this.setState({ index: currentIndex - 1 });
    }
    // after the last question you get the first one
    else {
      this.setState({ index: 0 });
    }
    this.setState({ notice: "" });
  }
  // checkfunction for onClick inside "Answer.js"
  _checkAnswer(x) {
    if (x === true) {
      this.setState({ notice: "it's right :-)", noticeColor: "green" });
    } else {
      this.setState({ notice: "it's wrong :-(", noticeColor: "red" });
    }
    console.log();
  }

  render() {
    // Integrate all components with Data from JSON
    return (
      <div className="quizbox">
        <Question
          qurrentQuestion={this.state.myQuestData[this.state.index].q}
        />

        <DisplayNotice
          qurrentNotice={this.state.notice}
          noticeColor={this.state.noticeColor}
        />

        <div className="answerbox">
          <Answer
            text={this.state.myQuestData[this.state.index].a1.text}
            checkValue={() =>
              this._checkAnswer(
                this.state.myQuestData[this.state.index].a1.value
              )
            }
          />

          <Answer
            text={this.state.myQuestData[this.state.index].a2.text}
            checkValue={() =>
              this._checkAnswer(
                this.state.myQuestData[this.state.index].a2.value
              )
            }
          />

          <Answer
            text={this.state.myQuestData[this.state.index].a3.text}
            checkValue={() =>
              this._checkAnswer(
                this.state.myQuestData[this.state.index].a3.value
              )
            }
          />

          <Answer
            text={this.state.myQuestData[this.state.index].a4.text}
            checkValue={() =>
              this._checkAnswer(
                this.state.myQuestData[this.state.index].a4.value
              )
            }
          />
        </div>

        <div className="buttonbox">
          <ButtonPrevious onClickPrevious={() => this._previousQuiz()} />
          <ButtonNext onClickNext={() => this._nextQuiz()} />
        </div>
      </div>
    );
  }
}

export default App;
