import React from "react";
import "./style.css";

class Answer extends React.Component {
  render() {
    return (
      <div className="labeltext" onClick={this.props.checkValue}>
        {this.props.text}
      </div>
    );
  }
}

export default Answer;
