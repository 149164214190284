import React from "react";
import "./style.css";

function Question(props) {
  return (
    <div className="questionbox">
      <h2>{props.qurrentQuestion}</h2>
    </div>
  );
}

export default Question;
