import React from "react";
import "./style.css";

class ButtonPrevious extends React.Component {
  render() {
    return (
      <div>
        <button className="buttonprevious" onClick={this.props.onClickPrevious}>
          « previous quiz
        </button>
      </div>
    );
  }
}

export default ButtonPrevious;
